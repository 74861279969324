// src/errors.ts
var PersonnummerError = class extends Error {
  constructor() {
    super("Invalid swedish personal identity number");
  }
};

// src/utils.ts
var compareAsc = (dateLeft, dateRight) => {
  const diff = dateLeft.getTime() - dateRight.getTime();
  return diff < 0 ? -1 : diff > 0 ? 1 : diff;
};
var diffInYears = (dateLeft, dateRight) => {
  const sign = compareAsc(dateLeft, dateRight);
  const yearDiff = Math.abs(dateLeft.getFullYear() - dateRight.getFullYear());
  dateLeft.setFullYear(dateLeft.getFullYear() - sign * yearDiff);
  const isLastYearNotFull = compareAsc(dateLeft, dateRight) === -sign;
  const result = sign * (yearDiff - +isLastYearNotFull);
  return result === 0 ? 0 : result;
};
var luhn = (str) => {
  let sum = 0;
  str += "";
  for (let i = 0, l = str.length; i < l; i++) {
    let v = parseInt(str[i]);
    v *= 2 - i % 2;
    if (v > 9) {
      v -= 9;
    }
    sum += v;
  }
  return Math.ceil(sum / 10) * 10 - sum;
};
var testDate = (year, month, day) => {
  month -= 1;
  const date = new Date(year, month, day);
  return !(date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day);
};

// src/index.ts
var Personnummer = class {
  /**
   * Personnummer constructor.
   *
   * @param {string} pin
   * @param {object} options
   */
  constructor(pin, options) {
    /**
     * Personnummer century.
     *
     * @var {string}
     */
    this._century = "";
    /**
     * Personnummer full year.
     *
     * @var {string}
     */
    this._fullYear = "";
    /**
     * Personnummer year.
     *
     * @var {string}
     */
    this._year = "";
    /**
     * Personnummer month.
     *
     * @var {string}
     */
    this._month = "";
    /**
     * Personnummer day.
     *
     * @var {string}
     */
    this._day = "";
    /**
     * Personnummer seperator.
     *
     * @var {string}
     */
    this._sep = "";
    /**
     * Personnumer first three of the last four numbers.
     *
     * @var {string}
     */
    this._num = "";
    /**
     * The last number of the personnummer.
     *
     * @var {string}
     */
    this._check = "";
    this.parse(pin, {
      allowCoordinationNumber: true,
      allowInterimNumber: false,
      ...options
    });
  }
  /**
   * Get century.
   *
   * @return {string}
   */
  get century() {
    return this._century;
  }
  /**
   * Get age.
   *
   * @return {string}
   */
  get fullYear() {
    return this._fullYear;
  }
  /**
   * Get age.
   *
   * @return {string}
   */
  get year() {
    return this._year;
  }
  /**
   * Get month.
   *
   * @return {string}
   */
  get month() {
    return this._month;
  }
  /**
   * Get day.
   *
   * @return {string}
   */
  get day() {
    return this._day;
  }
  /**
   * Get sep.
   *
   * @return {string}
   */
  get sep() {
    return this._sep;
  }
  /**
   * Get num.
   *
   * @return {string}
   */
  get num() {
    return this._num;
  }
  /**
   * Get check.
   *
   * @return {string}
   */
  get check() {
    return this._check;
  }
  /**
   * Parse personnummer.
   *
   * @param {string} pin
   * @param {object} options
   *
   * @return {Personnummer}
   */
  static parse(pin, options) {
    return new Personnummer(pin, options);
  }
  /**
   * Validate a Swedish personal identity number.
   *
   * @param {string} str
   * @param {object} options
   *
   * @return {boolean}
   */
  static valid(pin, options) {
    try {
      Personnummer.parse(pin, options);
      return true;
    } catch (e) {
      return false;
    }
  }
  /**
   * Parse personnummer and set class properties.
   *
   * @param {string} pin
   * @param {object} options
   */
  parse(pin, options) {
    if (pin.length < 10 || pin.length > 13) {
      throw new PersonnummerError();
    }
    const reg = /^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([+-]?)((?!000)\d{3}|[TRSUWXJKLMN]\d{2})(\d)$/;
    const match = reg.exec(pin);
    if (!match) {
      throw new PersonnummerError();
    }
    const century = match[1];
    const year = match[2];
    const month = match[3];
    const day = match[4];
    const sep = match[5];
    const num = match[6];
    const check = match[7];
    if (typeof century === "undefined" || !century.length) {
      const d = /* @__PURE__ */ new Date();
      let baseYear = 0;
      if (sep === "+") {
        this._sep = "+";
        baseYear = d.getFullYear() - 100;
      } else {
        this._sep = "-";
        baseYear = d.getFullYear();
      }
      this._century = ("" + (baseYear - (baseYear - parseInt(year)) % 100)).substr(0, 2);
    } else {
      this._century = century;
      if ((/* @__PURE__ */ new Date()).getFullYear() - parseInt(century + year, 10) < 100) {
        this._sep = "-";
      } else {
        this._sep = "+";
      }
    }
    this._year = year;
    this._fullYear = this._century + year;
    this._month = month;
    this._day = day;
    this._num = num;
    this._check = check;
    if (!this.valid()) {
      throw new PersonnummerError();
    }
    if (!(options == null ? void 0 : options.allowCoordinationNumber) && this.isCoordinationNumber()) {
      throw new PersonnummerError();
    }
    if (!(options == null ? void 0 : options.allowInterimNumber) && this.isInterimNumber()) {
      throw new PersonnummerError();
    }
  }
  /**
   * Validate a Swedish personal identity number.
   *
   * @return {boolean}
   */
  valid() {
    const valid = luhn(
      this.year + this.month + this.day + this.num.replace(/[TRSUWXJKLMN]/, "1")
    ) === +this.check && !!this.check;
    if (valid && testDate(parseInt(this.century + this.year), +this.month, +this.day)) {
      return valid;
    }
    return valid && testDate(parseInt(this.century + this.year), +this.month, +this.day - 60);
  }
  /**
   * Format a Swedish personal identity number as one of the official formats,
   * A long format or a short format.
   *
   * If the input number could not be parsed a empty string will be returned.
   *
   * @param {boolean} longFormat
   *
   * @return {string}
   */
  format(longFormat = false) {
    if (longFormat) {
      return `${this.century}${this.year}${this.month}${this.day}${this.num}${this.check}`;
    }
    return `${this.year}${this.month}${this.day}${this.sep}${this.num}${this.check}`;
  }
  /**
   * Get age from a Swedish personal identity number.
   *
   * @return {number}
   */
  getAge() {
    const date = this.getDate();
    return diffInYears(new Date(Date.now()), date);
  }
  /**
   * Get date from a Swedish personal identity number.
   *
   * @return {Date}
   */
  getDate() {
    let ageDay = +this.day;
    if (this.isCoordinationNumber()) {
      ageDay -= 60;
    }
    const ageDate = this.century + this.year + "-" + this.month + "-" + (ageDay < 10 ? "0" + ageDay : ageDay);
    return new Date(ageDate);
  }
  /**
   * Check if a Swedish personal identity number is a interim number or not.
   *
   * @return {boolean}
   */
  isInterimNumber() {
    return /[TRSUWXJKLMN]/.test(this.num[0]);
  }
  /**
   * Check if a Swedish personal identity number is a coordination number or not.
   *
   * @return {boolean}
   */
  isCoordinationNumber() {
    return testDate(
      parseInt(this.century + this.year),
      +this.month,
      +this.day - 60
    );
  }
  /**
   * Check if a Swedish personal identity number is for a female.
   *
   * @return {boolean}
   */
  isFemale() {
    return !this.isMale();
  }
  /**
   * Check if a Swedish personal identity number is for a male.
   *
   * @return {boolean}
   */
  isMale() {
    const sexDigit = parseInt(this.num.substr(-1));
    return sexDigit % 2 === 1;
  }
};
var src_default = Personnummer;
export {
  src_default as default
};
